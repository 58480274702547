<!--
 * @Author: your name
 * @Date: 2021-01-13 19:57:49
 * @LastEditTime: 2021-04-08 21:30:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\components\shareit\index.vue
-->
<template>
  <div>
    <share ref="shareid"></share>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import share from "../../components/share/index";
import base from "../../../util/base";
export default {
  components: {
    share,
  },
  methods: {
    /**
     * 查询调起微信分享的参数
     */
    getwxshare(shareobj) {
      let can = "?";
      Object.keys(this.$route.query).forEach((element) => {
        can += element + "=" + this.$route.query[element];
      });
      can.length > 1 ? can : (can = "");
      console.log("can====>",can);
      this.$http
        .post("/firm/v1/Wxshare/jssdk", {
          reqType: "userinfo",
          // url: base.url + "/" + shareobj.link.split("?")[0].split("/")[3],
          url: base.url + sessionStorage.getItem("url") + can,
        })
        .then((res) => {
          this.towxshare(JSON.parse(res.data), shareobj);
          setTimeout(() => {
            // this.$router.push("/sharepage");
          }, 1000);
        });
    },
    /**
     * 调起分享功能
     */
    towxshare(msg, shareobj) {
      wx.ready(function () {
        wx.updateAppMessageShareData({
          title: shareobj.title, // 分享标题
          desc: shareobj.desc, // 分享描述
          link: shareobj.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareobj.imgUrl, // 分享图标
          success: function (e) {},
        });
        wx.updateTimelineShareData({
          title: shareobj.title, // 分享标题
          desc: shareobj.desc, // 分享描述
          link: shareobj.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareobj.imgUrl, // 分享图标
          success: function (e) {},
        });
      });
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: msg.data.appId, // 必填，公众号的唯一标识
        timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg.data.nonceStr, // 必填，生成签名的随机串
        signature: msg.data.signature, // 必填，签名
        // jsApiList: ["updateAppMessageShareData","updateTimelineshareData","onMenuShareTimeline","onMenuShareAppMessage"], // 必填，需要使用的JS接口列表
        jsApiList: [
          "checkJsApi",
          "onMenuShareTimeline", //分享到微信朋友圈
          "onMenuShareAppMessage", //分享给微信朋友
          "onMenuShareQQ", //分享到QQ
          "onMenuShareQZone", //分享到QQ空间
          "updateAppMessageShareData", //分享到微信及QQ（新接口）
          "updateTimelineShareData", //分享到朋友圈”及“分享到QQ空间（新接口）
        ], // 必填，需要使用的JS接口列表
      });
    },
  },
};
</script>
<style scoped>
</style>